import React, { useState } from 'react';

const traitsData = {
  trait1: ['./assets/arm-hands/1.png', './assets/arm-hands/2.png', './assets/arm-hands/3.png', './assets/arm-hands/4.png', './assets/arm-hands/5.png', './assets/arm-hands/6.png', './assets/arm-hands/7.png', './assets/arm-hands/8.png'], // Add 10 images for each trait
  trait2: ['./assets/hair/1.png', './assets/hair/2.png', './assets/hair/3.png', './assets/hair/4.png', './assets/hair/5.png', './assets/hair/6.png', './assets/hair/7.png', './assets/hair/8.png'],
  trait3: ['./assets/mouth/1.png', './assets/mouth/2.png', './assets/mouth/3.png', './assets/mouth/4.png', './assets/mouth/5.png', './assets/mouth/6.png', './assets/mouth/7.png'],
  trait5: ['./assets/eyes/1.png', './assets/eyes/2.png', './assets/eyes/3.png', './assets/eyes/4.png', './assets/eyes/5.png']
  // Add data for the rest of the traits
};

const baseImage = './assets/body/base.png'; // Provide the URL of your base image here

const MemeGenerator = () => {
  const [selectedTraits, setSelectedTraits] = useState({});
  const [activeTraitIndex] = useState(0);

  const handleTraitChange = (traitIndex, direction) => {
    const activeTrait = Object.keys(traitsData)[traitIndex];
    const traitImages = traitsData[activeTrait];
    let newIndex = selectedTraits[activeTrait] || 0;
    newIndex += direction;
    if (newIndex < 0) newIndex = traitImages.length - 1;
    if (newIndex >= traitImages.length) newIndex = 0;
    setSelectedTraits({ ...selectedTraits, [activeTrait]: newIndex });
  };

  const handleDownload = async () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous'; // This is important for CORS handling
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    };
  
    try {
      // Load base image
      const baseImg = await loadImage(baseImage);
  
      // Set canvas size to base image size
      canvas.width = baseImg.width;
      canvas.height = baseImg.height;
  
      // Draw white background
      ctx.fillStyle = '#ffffff'; // White color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
  
      // Draw base image on canvas
      ctx.drawImage(baseImg, 0, 0);
  
      // Draw traits on canvas
      for (let trait in selectedTraits) {
        const traitImg = await loadImage(traitsData[trait][selectedTraits[trait]]);
        ctx.drawImage(traitImg, 0, 0);
      }
  
      // Create download link
      const link = document.createElement('a');
      link.download = 'pang.png';
      link.href = canvas.toDataURL();
      link.click();
    } catch (error) {
      console.error('Error loading images', error);
    }
  };  

  return (

    <div className="JC-Container">

      <div className="JC-navBar">
        
        <div className='JC-navLeft'>
          <a href="/" class="JC-navLogo">
            <img src="./assets/logo.png" alt="logo" className="JC-navImage"></img>
          </a>
        </div>
        
        <div className="JC-navRight">
          <a href="https://x.com/pangyasol" target="_blank" rel="noopener noreferrer" className="JC-socialLink">
            <i class="fa-brands fa-x-twitter"></i>
          </a>

          <a href="https://t.me/Pangyasol" target="_blank" rel="noopener noreferrer" className="JC-socialLink">
            <i class="fa-brands fa-telegram"></i>
          </a>

          <a href="https://pangyasol.xyz" target="_blank" rel="noopener noreferrer" className="JC-socialLink">
            <i class="fa-brands fa-safari"></i>
          </a>
        </div>

      </div>

      <div className="JC-TitleBox">
        <p className="JC-Title">PANG MEME MAKER</p>
      </div>

      <div className="meme-generator">

        <div className="JC-MemeBody">

          <img src={baseImage} alt="Base" className="base-image" />


          <div className="traits-container">
            {Object.keys(traitsData).map((trait, index) => (
              <div key={trait} className={`trait ${activeTraitIndex === index ? 'active' : ''} ${trait}-edit`}>
                <div className="trait-images">
                  <img src={traitsData[trait][selectedTraits[trait] || 0]} alt={trait} />
                </div>

                <div className="JC-ArrowBox">
                  <button className="JC-Arrow" onClick={() => handleTraitChange(index, -1)}>
                    <i class="fa-solid fa-arrow-left"></i>
                  </button>

                  <button className="JC-Arrow" onClick={() => handleTraitChange(index, 1)}>
                    <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>

        </div>
        
        <button className="JC-Button" onClick={handleDownload}>Download</button>

      </div>

    </div>
  );
};

export default MemeGenerator;
